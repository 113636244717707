@import "/src/styles/mixin";

.geo {
  margin-right: 32px;
  display: flex;
  align-items: center;
  gap: 6px;
  @media only screen and (max-width: 1559.98px) {
    margin-right: 24px;
  }
}
.button {
  @include font14Bold;
  @include dashedBottomLine;

  max-width: 118px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
