@import "/src/styles/mixin";

.dropdown {
  position: relative;

  &Button {
    @include font14Bold;
    display: flex;
    gap: 8px;
  }
}

.chevron {
  width: 16px;
  transition: transform 0.2s ease;
  &Active {
    width: 16px;
    transform: rotate(180deg);
    transition: transform 0.2s ease;
  }
}
