@import "/src/styles/mixin";

.list {
  &Wrap {
    position: relative;
    display: flex;
    gap: 24px;
    width: 100%;
    //max-width: 860px;

    .dropdown {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-height: 24px;
  overflow: hidden;
  gap: 32px;
  width: 100%;

  @media only screen and (min-width: 1440px) and (max-width: 1559.98px) {
    gap: 32px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1439.98px) {
    gap: 24px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: none;
  }
}
.item,
.button {
  @include font14Bold;
}

.item {
  @include lineHover;
  cursor: pointer;
  //@media only screen and (min-width: 1366px) and (max-width: 1480px) {
  //  &:nth-child(n + 8) {
  //    display: none;
  //  }
  //}
  //@media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
  //  &:nth-child(n + 5) {
  //    display: none;
  //  }
  //}
  //@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
  //  &:nth-child(n + 4) {
  //    display: none;
  //  }
  //}
}
