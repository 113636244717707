@import "/src/styles/mixin";

.dropdown {
  &List {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    position: absolute;
    top: 22px;
    left: 0;
    background-color: var(--white-color);
    padding: 24px;
    border-radius: var(--border-radius-small);
    -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
    z-index: 1;
  }
  &Item {
    //@media only screen and (min-width: 1366px) and (max-width: 1480px) {
    //  &:nth-child(-n + 4) {
    //    display: none;
    //  }
    //}
    //@media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    //  &:nth-child(-n + 4) {
    //    display: block;
    //  }
    //  &:first-child {
    //    display: none;
    //  }
    //}
    //@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    //  &:first-child {
    //    display: block;
    //  }
    //}
  }
  &Link,
  &Button,
  &Status {
    @include font14Bold;
    text-align: left;
    white-space: nowrap;
  }
}
