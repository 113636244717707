@import "/src/styles/mixin";

.wrap {
  position: fixed;
  top: 20px;
  width: 320px;
  left: 20px;
  padding: 20px 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius-medium);
  background-color: var(--white-color);
  z-index: 49;

  @media screen and (max-width: 767px) {
    position: fixed;
    top: auto;
    left: auto;
    right: 20px;
    bottom: 100px;
    height: fit-content;
    transform: translate(0, 0);
  }

  @media screen and (max-width: 425px) {
    width: calc(100vw - 20px);
    left: 50%;
    bottom: 100px;
    right: auto;
    transform: translate(-50%, 0);
  }

  .head {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .title {
      @include font16Bold;
    }

    & > button {
      position: absolute;
      top: 11px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > svg {
        height: 10px;
        width: 10px;
      }
    }
  }

  .text {
    @include font14;
    margin-bottom: 16px;
  }

  .buttons_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 10px;

    .button {
      @include font14;
      font-weight: 700;
      padding: 10px 0;
      width: 100%;
      transition: all 0.2s ease;
    }

    .button_incorrect {
      border-color: var(--grey-secondary);
      background-color: var(--grey-secondary);
      transition: all 0.2s ease;
    }

    .button_incorrect:hover {
      background-color: #d3d3d3;
      border-color: #d3d3d3;
    }
  }

  @media screen and (max-width: 767px) {
    z-index: 30;
  }
}
